<template>
  <el-popover
    @after-leave="closePopover"
    :visible-arrow="false"
    placement="bottom"
    trigger="click"
    ref="popover"
  >
    <TableComponent @click="getTableSize" ref="table-component" />
    <slot slot="reference" name="trigger"></slot>
  </el-popover>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Editor } from "@tiptap/vue-2";
import IPopover from "@/app/shared/components/html-editor/popover/ipopover";
import TableComponent from "./table.vue";

@Component({
  components: {
    TableComponent,
  },
})
export default class TablePicker extends Vue {
  @Prop({ required: true }) editor?: Editor;

  /** Type casting to element-ui popover component */
  $refs!: {
    popover: IPopover;
    "table-component": TableComponent;
  };

  private getTableSize({ rows, cols }: { rows: number; cols: number }): void {
    this.editor
      ?.chain()
      .focus()
      .insertTable({ rows, cols, withHeaderRow: true })
      .run();
    this.closePopover();
  }

  private closePopover(): void {
    this.$refs.popover.doClose();
    this.resetTableComponent();
  }

  private resetTableComponent(): void {
    this.$refs["table-component"].setTableToDefault();
  }
}
</script>

<style>
.el-popover {
  min-width: 70px !important;
}
</style>
