
















.wrapper {
  position: relative;

  &__hover {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transition: opacity 0.3s;
    background-color: rgba(26, 26, 26, 0.5);
    color: #fff;
  }

  &:hover &__hover {
    opacity: 1;
  }
}
