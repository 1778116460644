














































.upload {
  &__button {
    display: block;
    background: #f5f5f5;
    border: 1px dashed #b3b3b3;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #666666;
    padding: 30px 0;
    cursor: pointer;

    &:hover {
      border-color: #666666;
    }
  }
}
