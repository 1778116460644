

































.file {
  width: 140px;
  margin: 0 8px;

  &__title {
    margin: 8px 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
  }

  &__image-wrapper {
    width: 140px;
    height: 100px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__hover-icon {
    margin: 0 13px;
    color: #fff;
    cursor: pointer;
  }
}
