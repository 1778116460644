


















































































.additional-item {
  display: flex;
  align-items: center;

  &__value {
    margin: 0 6px;
  }
}
