<template>
  <div class="bubble-menu-container"><slot /></div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class BubbleMenu extends Vue {}
</script>

<style scoped>
.bubble-menu-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px #dbdbdb;
  border-radius: 4px;
  height: 40px;
}
</style>
