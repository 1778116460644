





































.symbols {
  border-spacing: 0;

  tr + tr td {
    border-top: 1px solid #e7e7e7;
    border-left: 0;
    border-right: 0;
  }

  &__cell {
    padding: 6px 6px;
    cursor: pointer;
    border: none;
  }
}
