










































.block-type {
  &__item {
    border-radius: 5px;
    display: inline-block;
    width: 84px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    font-weight: 500;
    font-size: 14px;

    &--info {
      background-color: #e9f2ff;
    }

    &--note {
      background-color: #f3f0ff;
    }

    &--success {
      background-color: #dcfff1;
    }

    &--warning {
      background-color: #fff7d6;
    }

    &--error {
      background-color: #ffeceb;
    }
  }
}
