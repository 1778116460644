





















.documents-tree {
  min-height: 200px;
  margin-top: 16px;
  padding: 30px;
  background: #f5f5f5;
  border: 1px solid #e7e7e7;
}
