























































































































































































































































































































































































































.document {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;

  .delimiter {
    height: 16px;
    width: 1px;
    background-color: #b2b2b2;
    margin: 0 24px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 32px;

    .el-form-item--small .el-form-item__error {
      padding-top: 0;
    }

    &-right {
      display: flex;
      align-items: center;
    }

    .c-icon-three-dots,
    &-publish {
      color: #808080;
    }

    &-options {
      margin-left: 24px;
      cursor: pointer;
      width: 20px;
    }
  }

  &__name {
    display: flex;
    align-items: center;
  }

  &__preview {
    flex-grow: 1;
    padding: 0 26px;
    overflow-y: auto;
    box-shadow: inset 0px 1px 0px #e7e7e7, inset 0px -1px 0px #e7e7e7;

    ::v-deep p:empty::after {
      content: "\00A0";
    }
  }

  &__footer {
    padding: 16px 32px;
    border-top: 1px solid #e7e7e7;
    background-color: #fafafa;
    text-align: right;
  }
}

.el-form-item {
  margin-bottom: 0;
}

.preview-button {
  .c-icon-monitor {
    transition: color 0.3s;
  }

  &:active,
  &:focus,
  &:hover {
    .c-icon-monitor {
      color: #fff;
    }
  }

  &__text {
    margin-left: 8px;
  }
}
