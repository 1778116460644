






















































































.file-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  height: 400px;
  overflow-y: auto;
  user-select: none;

  &__file {
    cursor: pointer;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 2px solid #e7e7e7;
    box-sizing: border-box;

    &-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }

    &-corner {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 32px 32px 0;
      border-color: transparent #27ae60 transparent transparent;

      .c-icon-check {
        color: #fff;
        font-size: 10px;
        position: absolute;
        right: -28px;
        top: 6px;
      }
    }
  }

  &__image-wrapper_selected &__image {
    border-color: #27ae60;
  }

  &__image-wrapper_selected &__image-corner {
    display: block;
  }
}

.c-icon-eye {
  margin: 0 13px;
  color: #fff;
  cursor: pointer;
}
