






































.toolbar-button {
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 4px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  transition: background-color 0.2s;

  &:hover {
    background: #e7e7e7;
  }

  &_active {
    background-color: #333;

    /deep/[class^="c-icon-"] {
      color: white !important;
    }

    &:hover {
      background-color: #333;
    }
  }
}
