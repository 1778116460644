





























































































































































































































































































































































































































































.html-editor {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 26px;
    overflow-y: auto;

    .ProseMirror {
      outline: none;
      height: 100%;
    }
  }
}

.c-icon-arrow-down {
  font-size: 10px;
  margin: 0 4px 0 8px;
}

blockquote {
  border-left: 1px solid #333333;
  margin: 0;
  padding-left: 8px;
  line-height: 18px;
}

.ProseMirror {
  .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(200, 200, 255, 0.4);
    pointer-events: none;
  }

  .column-resize-handle {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: -2px;
    width: 4px;
    background-color: #adf;
    pointer-events: none;
  }
}

.ProseMirror-selectednode {
  border: 1px solid rgb(175, 60, 60);
}
