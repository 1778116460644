
































.color-picker {
  &__item {
    margin: 0 3px;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid #e7e7e7;
    cursor: pointer;
  }
}
