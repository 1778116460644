














.toolbar {
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 4px 6px;
  box-sizing: border-box;
  background-color: #fafafa;
  box-shadow: inset 0px 1px 0px #e7e7e7, inset 0px -1px 0px #e7e7e7;

  &__item {
    display: flex;
    align-items: center;
  }
}
