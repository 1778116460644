


























































































































































.table-container {
  .table {
    display: inline-flex;
    flex-direction: column;
    position: relative;

    &__overlay {
      position: absolute;
      left: 0;
      top: 0;
      right: -20px;
      bottom: -20px;
      cursor: pointer;
    }

    &__row {
      display: flex;
    }

    &__cell {
      border: 4px solid transparent;
      width: 14px;
      height: 14px;
      border: 1px solid #808080;
      background-color: #fff;
      border-radius: 2px;

      &-container {
        padding: 4px;
      }

      &-selected {
        background-color: #e7e7e7;
      }
    }
  }
  &__size {
    text-align: center;
  }
}
