














































































































h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: 100;
}

blockquote {
  line-height: 30px;
}

.active-block {
  white-space: nowrap;
}
