











.toolbar-group {
  display: inline-flex;
  align-items: center;
  padding: 0 11px;
  height: 24px;
}

.toolbar-group + .toolbar-group {
  border-left: 1px solid #cccccc;
}
