









































































.image-attached-to-page {
  &__title {
    margin: 16px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }
}

.file-list {
  display: flex;
  overflow-x: auto;
  margin: 16px -8px 0;
}

[class^="c-icon-"] {
  margin: 0 13px;
  color: #fff;
  cursor: pointer;
}
